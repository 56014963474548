* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 16px;
  font-family: 'Lato',
    sans-serif;
}

main {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

}

@media only screen and (max-width: 480px) {
  * {
    font-size: 12px;
  }
}