header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem;

}

.college-logo {
    width: 35%;
}

.brl-logo {
    width: 15%;
}

@media only screen and (max-width: 480px) {
    .college-logo {
        width: 100%;
    }

    .brl-logo {
        display: none;
    }
}