.options-section {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, minmax(0, 2fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));

    width: 70vw;
    margin: auto;
    padding: 1rem 0;
}

.option {
    background-color: #1688ca;
    border: none;
    outline: 0.1rem solid #0056b3;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;

    padding: 1.5rem 3rem;
    width: 30vw;

    font: normal 400 1.5rem/2rem "Lato";
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}


@media only screen and (max-width: 480px) {
    .options-section {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        gap: 1rem;

        width: 95vw;
    }

    .option {
        width: 90vw;
    }
}